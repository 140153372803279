<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" lg="7">
        <div class="d-flex pl-2 align-items-center">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
              " />
          <div class="w-100 pl-2 pr-0 mr-0">
            <b-input-group>
              <b-form-input id="search-users" v-model="filter.search" placeholder="" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="5" class="px-0 justify-content-center d-flex align-items-end" style="gap: 4px">
        <b-button variant="primary" @click="resetFilter"> Reset </b-button>
        <b-button variant="primary" @click="exportReport">
          Export Data
        </b-button>
        <b-button variant="primary" @click="addUser">
          Tambah Pengguna
        </b-button>
      </b-col>
    </b-row>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.merchant && item.merchant.name || '-' }}</td>
              <td>{{ item.branch && item.branch.name || '-' }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="actions">
                  <b-button variant="primary" size="sm" @click="editItem(item.id)">
                    Lihat Detail
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import moment from "moment";
import FileSaver from "file-saver";
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    BCardCode,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    getDetail: {
      type: Function,
    },
    addUser: {
      type: Function,
    },
    result: {
      type: Object,
    },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Pengguna",
          field: "name",
        },
        {
          label: "Nama Toko",
          field: "merchant.name",
        },
        {
          label: "Nama Cabang",
          field: "branch.name",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "asc",
        per_page: 10,
      },
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBranch", value);
      },
      deep: true,
    },
  },
  methods: {
    resetFilter() {
      this.filter = {
        search: "",
        sort_type: "desc",
        per_page: 10,
      };
    },
    // goToDetail(uuid) {
    //   this.getDetail(uuid);
    //   this.$bvModal.show("modal-user");
    // },
    async exportReport() {
      // const queryParams = filter

      const response = await this.$http.get(
        "/super-admin/user-merchant/export",
        {
          responseType: "blob",
          // params: queryParams,
        }
      );

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      fileName = `daftar-pengguna-${timestamp}.xlsx`;
      FileSaver.saveAs(response.data, fileName);
      // console.log(response, ' report')
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
